import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: '/',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders.vue')
    },
    {
      path: '/send',
      name: 'send',
      component: () => import('@/views/send.vue')
    },
    {
      path: '/schools',
      name: 'schools',
      component: () => import('@/views/schools.vue')
    },
    {
      path: '/school/:id',
      name: 'school',
      component: () => import('@/views/school.vue')
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/archive.vue')
    },
    {
      path: '/offers',
      name: 'offers',
      component: () => import('@/views/offers.vue')
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('@/views/stats.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
