import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "https://server.naqar.net"
// window.api = "http://127.0.0.1:3000"
if(window.location.href.includes(":808")){
  window.api = "http://127.0.0.1:3000"
}
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

var allowed = [
  "/login"
]
if(!localStorage.getItem('jwt')){
  setInterval(function(){
    if(!localStorage.getItem("jwt") && !allowed.includes(window.location.pathname)){
      window.location = '/login'
    }
  }, 10)
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
